import React from 'react';

import { CDN_PSPDFKIT_BASE_URL } from 'lib/pdf/constants';

export const PrefetchPSPDFKit = () => {
  const baseUrl = CDN_PSPDFKIT_BASE_URL;
  return (
    <>
      {/* Preloading pspdfkit dependencies: start */}
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/chunk-standalone-4f54e4cff41ba279.js`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/chunk-locale-en-GB-e760e15d7f53b988.js`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/chunk-locale-en-be713de2e89e142b.js`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/chunk-localedata-en-862c7d2ed9a5ad1d.js`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/pspdfkit-2024.3.2.css`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/dark-3708e5109bf0f980.css`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/pspdfkit-d65f8c86d3c3e05c.wasm`} />
      <link rel="prefetch" href={`${baseUrl}pspdfkit-lib/pspdfkit-a40f15a37f0211b1.wasm.js`} />
      {/* Preloading pspdfkit dependencies: end */}
    </>
  );
};
